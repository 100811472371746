@media print {
  body,
  html {
    margin-top: 0%;
    overflow-y: visible !important;
    display: block !important;
    height: 100% !important;
    width: 100%;
  }

  .hide-on-print {
    display: none !important;
  }

  .full-width-on-print {
    width: 100% !important;
    margin: 0px;
  }
}
