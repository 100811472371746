
html {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body,
#__next,
#__next-inner,
main {
  margin: 0;
  padding: 0;
  min-width: 360px;
}

body,
#__next,
#__next-inner,
main {
  min-height: 100vh;
}

#__next-inner {
  width: 100%;
}

html,
body,
p,
div,
ol,
ul,
li,
dl,
dt,
dd,
blockquote,
figure,
fieldset,
legend,
textarea,
pre,
iframe,
hr,
h1,
h2,
h3,
h4,
h5,
h6,
button,
input,
select,
textarea {
  font-family: var(--font-roboto), 'Roboto', Arial, Helvetica, sans-serif;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 500;
}

ul {
  list-style: disc;
}

button,
input,
select,
textarea {
  margin: 0;
}

input::placeholder {
  color: var(--colors-inputs-placeholder);
}
input::-webkit-input-placeholder {
  color: var(--colors-inputs-placeholder);
}

fieldset {
  border-width: 0;
}

button,
a {
  cursor: pointer;
}

img,
video {
  height: auto;
  max-width: 100%;
}

iframe {
  border: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  padding: 0;
}

td:not([align]),
th:not([align]) {
  text-align: left;
}

ol, ul {
  margin-left: 16px;
}

mark {
  background-color: transparent;
}