
#__next {
  display: flex;
}

.react-datepicker-wrapper {
  width: 100%;
}

[data-tippy-root] {
  z-index: 99 !important;
}

a > button {
  text-decoration: none;
}

#nprogress .bar {
  background: var(--colors-buttons-primary-background) !important;
}
