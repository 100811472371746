.craft-layer-node .inner > div { 
  margin-left: 0 !important;
}

.craft-layer-node {
  border-left: 1px solid grey;
}
.craft-layer-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.layer-name {
  font-weight: bold;
}

.craft-layer-children {
  padding-left: 24px;
}