.tiptap .tippy-content {
  background: var(--colors-layout-background);
  padding: 0;
  margin: 0;
  border-radius: 5px;
}

.tippy-box {
  background: var(--colors-layout-background);
  border: solid 0.5px var(--colors-borders-lists);
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  /* font-size: 0; */
}

.tippy-arrow {
  color: var(--colors-borders-lists);
}

.tippy-box[data-placement^='right'] .tippy-arrow::before {
  border-right-color: var(--colors-borders-lists);
  left: -8px;
}
.tippy-box[data-placement^='left'] .tippy-arrow::before {
  border-left-color:  var(--colors-borders-lists);
  right: -8px;
}
.tippy-box[data-placement^='top'] .tippy-arrow::before {
  border-top-color: var(--colors-borders-lists);
  bottom: -8px;
}
.tippy-box[data-placement^='bottom'] .tippy-arrow::before {
  border-bottom-color: var(--colors-borders-lists);
  top: -8px;
}

.ProseMirror {
  position: relative;
  min-height: 40px;
  padding-top: 8px;
  padding-left: 4px;
}

.ProseMirror-focused {
  outline-width: 2px;
}

.tiptap-aurelius {
  border-radius: 5px;
}

/* Placeholder (at the top) */
.ProseMirror p.is-editor-empty::before {
  content: attr(data-placeholder);
  font-size: 16px;
  font-weight: 500;
  color: var(--colors-inputs-placeholder);
  content: attr(data-placeholder);
  pointer-events: none;
  position: absolute;
  top: 8px;
  left: 4px
}

.ProseMirror p {
  min-height: 24px;
}

.component {
  border: 1px solid transparent;
  color: black;
  margin: -1px;
  position: relative;
  display: flex;
  align-self: flex-start;
}

.component .tiptap.ProseMirror {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}
.component-selected,
.component-hovered {
  border: 1px dashed #aaa;
}

.link {
  color: gray;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
  font-family: 'Karla', sans-serif;
}

.bubble-menu-container {
  position: relative;
  height: 56px;
  padding: 4px 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background-color: black;
  border-radius: 5px;
}

.bubble-menu-container button {
  width: 32px;
  height: 32px;
  background-color: transparent;
  border: 0;
  margin-right: 6px;
  color: white;
  font-size: 24px;
}

.edit-link {
  height: auto;
  display: flex;
  text-align: center;
  box-sizing: border-box;
  margin: 12px 8px;
  margin-bottom: 12px !important;
}

.edit-link button {
  width: 32px;
  background-color: transparent;
  border: 0;
  margin: 0;
  padding: 0;
}

.edit-link input {
  height: 24px;
  outline: none;
  border: 0;
  padding: 0;
}

.ProseMirror h1,
.ProseMirror h2,
.ProseMirror h3,
.ProseMirror h4,
.ProseMirror h5,
.ProseMirror h6 {
  line-height: 1.1;
  min-height: 24px;
}

.customEditDescriptionBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.hex-color-picker-tiptap-bubble-menu {
  position: absolute;
  z-index: 999;
  top: 60px;
  left: 0;
  right: 0;
}

.color-icon {
  display: block;
  border-radius: 80px;
  box-shadow: 0 0 0 0.3px violet, 0 0 0 0.6px indigo, 0 0 0 0.9px blue,
    0 0 0 1.2px green, 0 0 0 1.5px yellow, 0 0 0 1.8px orange, 0 0 0 2px red;
  height: 25px;
  width: 25px;
}

.text-editor-is-active-icon>s {
  color: #A8DCE3;
}

.text-editor-is-active-icon>svg>path {
  fill: #A8DCE3;
}